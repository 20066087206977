import React, { FunctionComponent, useState } from 'react'

export interface UserIconType {
  icon: 'UserIcon'
  options?: UserIconPropsType
}

interface UserIconPropsType {
  className?: string
  color: UserIconStateType
  hoverColor: UserIconStateType
}

interface UserIconStateType {
  colorPrimary: string
  colorSecondary: string
}

const UserIcon: FunctionComponent<UserIconPropsType> = (props) => {
  const defaultTheme = props.color
  const hoverTheme = props.hoverColor
  const [color, setColor] = useState<string>(props.color.colorPrimary)

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
      className={props.className?.toLowerCase()}
      onMouseEnter={() => setColor(props.color.colorSecondary)}
      onMouseLeave={() => setColor(props.color.colorPrimary)}
    >
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Vrstva_1' data-name='Vrstva 1'>
          <path
            fill={color}
            d='M10,10A5,5,0,1,0,5,5,5,5,0,0,0,10,10Zm0-8A3,3,0,1,1,7,5,3,3,0,0,1,10,2ZM20,17v2a1,1,0,0,1-2,0V17a3,3,0,0,0-3-3H5a3,3,0,0,0-3,3v2a1,1,0,0,1-2,0V17a5,5,0,0,1,5-5H15A5,5,0,0,1,20,17Z'
          />
        </g>
      </g>
    </svg>
  )
}

export default UserIcon
