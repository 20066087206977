import './PuxLanguageSelector.scss'

import React, { Dispatch, FunctionComponent, SetStateAction } from 'react'
import { automaticLocalizationConfig } from 'Root/puxconfig'
import {
  getIsoCodesWithPrefixPublic,
  getLocalizedUrl,
  isoCodePrefix,
} from 'Shared/utils/localeURL'
import { t } from 'ttag'

import FlagBg from './flags/bg.svg'
import FlagBr from './flags/br.svg'
import FlagCs from './flags/cs.svg'
import FlagDe from './flags/de.svg'
import FlagDk from './flags/dk.svg'
import FlagEn from './flags/en.svg'
import FlagEs from './flags/es.svg'
import FlagFr from './flags/fr.svg'
import FlagGr from './flags/gr.svg'
import FlagHu from './flags/hu.svg'
import FlagIl from './flags/il.svg'
import FlagIt from './flags/it.svg'
import FlagJp from './flags/jp.svg'
import FlagKr from './flags/kr.svg'
import FlagNl from './flags/nl.svg'
import FlagNo from './flags/no.svg'
import FlagPl from './flags/pl.svg'
import FlagRu from './flags/ru.svg'
import FlagSe from './flags/Se.svg'
import FlagTr from './flags/tr.svg'

const rootClass = `pux-language-selector`

const getFlagUrl = (lang: string) => {
  switch (lang) {
    case `fr`:
      return FlagFr
    case `kr`:
      return FlagKr
    case `ru`:
      return FlagRu
    case `de`:
      return FlagDe
    case `es`:
      return FlagEs
    case `br`:
      return FlagBr
    case `jp`:
      return FlagJp
    case `it`:
      return FlagIt
    case `dk`:
      return FlagDk
    case `bg`:
      return FlagBg
    case `gr`:
      return FlagGr
    case `hu`:
      return FlagHu
    case `il`:
      return FlagIl
    case `it`:
      return FlagIt
    case `nl`:
      return FlagNl
    case `no`:
      return FlagNo
    case `tr`:
      return FlagTr
    case `se`:
      return FlagSe
    case `pl`:
      return FlagPl
    case `cs`:
      return FlagCs
    default:
      return FlagEn
  }
}

interface PuxLanguageSelectorType {
  isOpen: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  localizedPaths?: localizedPathType[]
}

export interface localizedPathType {
  path: string
  published: boolean
  localization: {
    culture: string
  }
}

const PuxLanguageSelector: FunctionComponent<PuxLanguageSelectorType> = (
  props
) => {
  const locales = getIsoCodesWithPrefixPublic(true)
  const activeLang: string = process.env.LOCALE ? process.env.LOCALE : `en`
  const project: string = process.env.PROJECT
    ? process.env.PROJECT
    : `EasyRedmineGatsby`

  const getLocalizedPath = (locale: isoCodePrefix): string => {
    const localizedPaths = props.localizedPaths
    const defaultPath = `${locale.domain}/${locale.prefix}`

    if (!localizedPaths) {
      return defaultPath
    }

    const localizedPath = localizedPaths.filter(
      (localizedPath) => localizedPath.localization.culture === locale.isoCode
    )

    if (!localizedPath.length) {
      return defaultPath
    }

    const localizedUrl = getLocalizedUrl(localizedPath[0].path)

    if (
      localizedUrl.startsWith(`http://`) ||
      localizedUrl.startsWith(`https://`)
    ) {
      return localizedUrl
    }

    return locale.domain + localizedUrl
  }

  const getAutomaticLocalizedPath = (path: string): string => {
    const localizedPaths = props.localizedPaths
    const defaultPath = path

    if (!localizedPaths) {
      return defaultPath
    }

    const localizedPath = localizedPaths.filter(
      (localizedPath) => localizedPath.localization.culture === `en-GB`
    )

    if (!localizedPath.length) {
      return defaultPath
    }

    return (
      path + localizedPath[0].path.replace(`en-gb/`, ``).replace(`en-GB/`, ``)
    )
  }

  return (
    <div className={rootClass}>
      <div
        className={`${rootClass}-activator`}
        onClick={() => {
          props.setOpen(!props.isOpen)
        }}
        onMouseEnter={() => {
          props.setOpen(true)
        }}
      >
        <img
          className={`${rootClass}-flag`}
          src={getFlagUrl(activeLang)}
          alt={activeLang}
        />
      </div>

      <div
        className={`${rootClass}-dialog ${props.isOpen ? rootClass + `-dialog-open` : ``
          }`}
        onMouseLeave={() => {
          props.setOpen(false)
        }}
      >
        <h4 className={`${rootClass}-heading`}>
          {t`Redmine.LanguageSelector.Language`}
        </h4>
        <ul className={`${rootClass}-items`}>
          {locales.reverse().map((locale, idx) => (
            <li key={idx}>
              <a
                className={activeLang === locale.locale ? `is-active` : ``}
                href={getLocalizedPath(locale)}
              >
                <img
                  className={`${rootClass}-flag`}
                  src={getFlagUrl(locale.locale)}
                  alt={locale.locale}
                />
              </a>
            </li>
          ))}
        </ul>
        {automaticLocalizationConfig[project]?.length > 0 && (
          <>
            <h4 className={`${rootClass}-heading`}>
              {t`Redmine.LanguageSelector.Automatic.Language`}
            </h4>
            <ul className={`${rootClass}-items`}>
              {automaticLocalizationConfig[project].map((item, idx) => (
                <li key={idx}>
                  <a
                    className={activeLang === item.id ? `is-active` : ``}
                    href={getAutomaticLocalizedPath(item.url)}
                  >
                    <img
                      className={`${rootClass}-flag`}
                      src={getFlagUrl(item.id)}
                      alt={item.id}
                    />
                  </a>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  )
}

export default PuxLanguageSelector
