import React, { FunctionComponent, useState } from 'react'

export interface InfoIconType {
  icon: 'InfoIcon'
  options?: InfoIconPropsType
}

interface InfoIconPropsType {
  className?: string
  color: InfoIconStateType
  hoverColor: InfoIconStateType
}

interface InfoIconStateType {
  colorPrimary: string
}

const InfoIcon: FunctionComponent<InfoIconPropsType> = (props) => {
  const defaultTheme = props.color
  const hoverTheme = props.hoverColor
  const [colorTheme, setColorTheme] = useState<InfoIconStateType>(defaultTheme)

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
      width='100%'
      className={props.className?.toLowerCase()}
      onMouseEnter={() => setColorTheme(hoverTheme)}
      onMouseLeave={() => setColorTheme(defaultTheme)}
    >
      <g>
        <path
          fill={colorTheme.colorPrimary}
          d='M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,10,18Zm1-9v5a1,1,0,0,1-2,0V9a1,1,0,0,1,2,0Zm-.08-3.38A.84.84,0,0,1,11,6a1,1,0,0,1-.29.7,1,1,0,0,1-1.42,0A1,1,0,0,1,9,6a1,1,0,0,1,.08-.38,1.15,1.15,0,0,1,.21-.33,1,1,0,0,1,1.42,0A1.15,1.15,0,0,1,10.92,5.62Z'
        />
      </g>
    </svg>
  )
}

export default InfoIcon
