import React, { FunctionComponent, useState } from 'react'

export interface SquareArrowIconType {
  icon: 'SquareArrowIcon'
  options?: SquareArrowIconPropsType
}

interface SquareArrowIconPropsType {
  className?: string
  color: SquareArrowIconStateType
  hoverColor: SquareArrowIconStateType
}

interface SquareArrowIconStateType {
  colorPrimary: string
  colorSecondary: string
}

const SquareArrowIcon: FunctionComponent<SquareArrowIconPropsType> = (
  props
) => {
  const defaultTheme = props.color
  const hoverTheme = props.hoverColor
  const [color, setColor] = useState<string>(props.color.colorPrimary)

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
      className={props.className?.toLowerCase()}
      onMouseEnter={() => setColor(props.color.colorSecondary)}
      onMouseLeave={() => setColor(props.color.colorPrimary)}
    >
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Vrstva_1' data-name='Vrstva 1'>
          <path
            fill={color}
            d='M20,3V17a3,3,0,0,1-3,3H13a1,1,0,0,1,0-2h4a1,1,0,0,0,1-1V3a1,1,0,0,0-1-1H11V13.59l2.29-2.3a1,1,0,0,1,1.42,1.42l-4,4a1,1,0,0,1-.33.21.94.94,0,0,1-.76,0,1,1,0,0,1-.33-.21l-4-4a1,1,0,0,1,1.42-1.42L9,13.59V2H3A1,1,0,0,0,2,3V17a1,1,0,0,0,1,1H7a1,1,0,0,1,0,2H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0H17A3,3,0,0,1,20,3Z'
          />
        </g>
      </g>
    </svg>
  )
}

export default SquareArrowIcon
