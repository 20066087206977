import React, { FunctionComponent, useState } from 'react'

export interface PlayIconType {
  icon: 'PlayIcon'
  options?: PlayIconPropsType
}

interface PlayIconPropsType {
  className?: string
  color: PlayIconStateType
  hoverColor: PlayIconStateType
}

interface PlayIconStateType {
  colorPrimary: string
  colorSecondary: string
}

const PlayIcon: FunctionComponent<PlayIconPropsType> = (props) => {
  const defaultTheme = props.color
  const hoverTheme = props.hoverColor
  const [colorTheme, setColorTheme] = useState<PlayIconStateType>(defaultTheme)

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 60 60'
      className={props.className?.toLowerCase()}
      onMouseEnter={() => setColorTheme(hoverTheme)}
      onMouseLeave={() => setColorTheme(defaultTheme)}
    >
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Vrstva_1' data-name='Vrstva 1'>
          <circle fill={colorTheme.colorPrimary} cx='30' cy='30' r='30' />
          <path
            fill={colorTheme.colorSecondary}
            d='M41,30a2.4,2.4,0,0,1-1.21,2L26.35,40.56h0A2.64,2.64,0,0,1,25,41a1.88,1.88,0,0,1-.9-.23A2.26,2.26,0,0,1,23,38.69V21.31a2.26,2.26,0,0,1,1.06-2.08,2.21,2.21,0,0,1,2.29.21L39.79,28A2.4,2.4,0,0,1,41,30Z'
          />
        </g>
      </g>
    </svg>
  )
}

export default PlayIcon
