import React, { FunctionComponent } from 'react'
import PuxAnchor from 'Shared/components/PuxAnchor/PuxAnchor'
import { t } from 'ttag'

import SignUpForm from '../MauticForms/MauticTrialForm'

const rootClass = `mautictrial-form`

const PuxNewsletterForm: FunctionComponent = () => {
  return (
    <PuxAnchor id='trial'>
      <div className={rootClass}>
        <div className='pux-container'>
          <h2 className={`${rootClass}-title`}>
            {t`EasySoftware.Newsletter.Title`}
          </h2>
          <p className={`${rootClass}-perex perex`}>
            {t`EasySoftware.Newsletter.Perex`}
          </p>
          <SignUpForm specificID='newsletter-widget' />
        </div>
      </div>
    </PuxAnchor>
  )
}

export default PuxNewsletterForm
