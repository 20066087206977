/* eslint-disable prettier/prettier */
import { useLocation } from '@reach/router'
import { Link } from 'gatsby'
import { decode } from 'html-entities'
import React, { Dispatch, FunctionComponent, SetStateAction } from 'react'
import { getLocalizedUrl } from 'Shared/utils/localeURL'
import { MenuCategory, MenuItem, TopMenuItem } from 'Shared/utils/menuItemsMapper'
import { PuxAnchorLink } from 'Shared/utils/PuxAnchorLink/PuxAnchorLink'

export interface HeaderData {
  menuItems: TopMenuItem[]
  supportArticle: any
}
interface HeaderNavProps {
  headerData: HeaderData
  setLanguageDialogOpen: Dispatch<SetStateAction<boolean>>
}
export interface renderData {
  url: string
  name: string
  internal?: boolean
}

export interface MenuDuplicate {
  main: MenuItem[]
  nav: MenuItem[]
}

const renderMenuItem = (
  data: MenuItem,
  index: number,
  renderDuplicateAlias = false
) => {
  switch (data.contentType) {
    case `ContentMenuItem`:
      const itemNavData: renderData = JSON.parse(data.render)
      return (
        <li key={index}>
          <Link
            activeClassName='is-active'
            partiallyActive={true}
            to={`${getLocalizedUrl(itemNavData.url)}`}
          >
            {renderDuplicateAlias
              ? data.contentMenuItemDuplicateAlias
                ? data.contentMenuItemDuplicateAlias
                : decode(itemNavData.name)
              : decode(itemNavData.name)}
          </Link>
        </li>
      )
    case `LinkMenuItem`:
      return (
        <li key={index}>
          <a href={data.linkMenuItem?.url} target='_blank' rel='noopener'>
            {data.linkMenuItem?.name}
          </a>
        </li>
      )
    default:
      return null
  }
}

const renderCategory = (data: MenuCategory, index: number) => {
  if (data.menuItemsList !== null) {
    return (
      <div className='submenu-content-category' key={index}>
        <div className='submenu-content-category-heading'>
          {data.categoryItemName}
        </div>
        {data.categoryItemDescription ? (
          <div className='submenu-content-category-description'>
            {data.categoryItemDescription}
          </div>
        ) : null}
        <nav>
          <ul>
            {data.menuItemsList.menuItems.map((menuItem, index) =>
              renderMenuItem(menuItem, index)
            )}
          </ul>
        </nav>
      </div>
    )
  } else {
    return null
  }
}

const renderDuplicate = (data: TopMenuItem) => {
  const duplicate: MenuDuplicate = {
    main: [
      {
        contentType: `ContentMenuItem`,
        contentMenuItemDuplicateAlias: data.contentMenuItemDuplicateAlias,
        render: data.render,
      },
    ],
    nav: [],
  }

  data.menuItemsList?.menuItems.map((category) => {
    category.menuItemsList.menuItems.map((menuItem) => {
      if (menuItem.contentMenuItemDuplicateHide !== true) {
        menuItem.contentMenuItemDuplicateMainItem
          ? duplicate.main.push(menuItem)
          : duplicate.nav.push(menuItem)
      }
    })
  })

  return (
    <div
      className='submenu-duplicated'
      onMouseOver={(e) => e.stopPropagation()}
    >
      <ul className='main'>
        {duplicate.main.map((item, index) => renderMenuItem(item, index, true))}
      </ul>
      <ul className='nav'>
        {duplicate.nav.map((item, index) => renderMenuItem(item, index, true))}
      </ul>
    </div>
  )
}

const renderSubmenu = (data: TopMenuItem, hoverFnc: any) => {
  return (
    <div
      className={`submenu${data.menuItemsList?.menuItems.length == 1 ? ` simple` : ``
        }`}
      onMouseEnter={() => hoverFnc(true)}
      onMouseLeave={() => hoverFnc(false)}
    >
      <div className={`submenu-content`}>
        {data.menuItemsList?.menuItems.map((category, index) =>
          renderCategory(category, index)
        )}
      </div>
      {data.contentMenuItemCTA?.html ? (
        <div className='submenu-widget'>
          <div
            className='submenu-widget-content'
            dangerouslySetInnerHTML={{
              __html: data.contentMenuItemCTA.html,
            }}
          ></div>
        </div>
      ) : null}
    </div>
  )
}

const renderTopMenuItem = (data: TopMenuItem, index: number, setLanguageDialogOpen: any) => {
  const [expanded, setExpanded] = React.useState(false)
  const [hovered, setHovered] = React.useState(false)
  const itemNavData: renderData = JSON.parse(data.render)
  const location = useLocation()

  function hasActiveDuplicateMenu(): boolean {
    if (location.pathname === getLocalizedUrl(itemNavData?.url)) {
      return true
    }

    let isActive = false
    if (data.menuItemsList != null) {
      data.menuItemsList.menuItems.map((category) => {
        category.menuItemsList.menuItems.map((menuItem) => {
          if (menuItem.contentType === `ContentMenuItem`) {
            const menuItemData: renderData = JSON.parse(menuItem.render)
            if (getLocalizedUrl(menuItemData?.url) === location.pathname) {
              isActive = true
            }
          }
        })
      })
    }

    return isActive
  }

  hasActiveDuplicateMenu()
  const pathname = location.pathname
  const isInternal = itemNavData.internal ?? true

  return (
    <li
      className={`${data.menuItemsList != null && data.menuItemsList.menuItems.length ? `has-submenu` : ``}${hasActiveDuplicateMenu() ? ` has-active-duplicate` : ``
        }${expanded ? ` expand` : ``}${hovered ? ` is-hovered` : ``}`}
      key={index}
    >
      {itemNavData.url.startsWith(`#`) &&
        <PuxAnchorLink
          className={hasActiveDuplicateMenu() ? `has-active-duplicate` : ``}
          to={pathname + itemNavData.url}
        >
          {decode(itemNavData.name)}
        </PuxAnchorLink>
      }
      {!itemNavData.url.startsWith(`#`) && isInternal &&
        <Link
          activeClassName='is-active'
          partiallyActive={true}
          className={hasActiveDuplicateMenu() ? `has-active-duplicate` : ``}
          to={`${getLocalizedUrl(itemNavData.url)}`}
          onMouseEnter={() => {
            setLanguageDialogOpen(false)
            setHovered(true)
          }}
          onMouseLeave={() => setHovered(false)}
        >
          {decode(itemNavData.name)}
        </Link>
      }
      {!itemNavData.url.startsWith(`#`) && !isInternal &&
        <a
          href={itemNavData.url}
          onMouseEnter={() => {
            setLanguageDialogOpen(false)
            setHovered(true)
          }}
          onMouseLeave={() => setHovered(false)}>
          {decode(itemNavData.name)}
        </a>
      }
      {data.menuItemsList != null && data.menuItemsList.menuItems.length ? (
        <>
          <div
            className='expander'
            onClick={(e) => {
              e.preventDefault()
              setExpanded(!expanded)
            }}
          ></div>
          {renderSubmenu(data, setHovered)}
          {renderDuplicate(data)}
        </>
      ) : null}
    </li>
  )
}

const HeaderNav: FunctionComponent<HeaderNavProps> = (props) => {
  return (
    <nav className={`header-menu header-menu-default`}>
      <ul>
        {props?.headerData?.menuItems?.map((item, index) => renderTopMenuItem(item, index, props.setLanguageDialogOpen))}
      </ul>
    </nav>
  )
}

export default HeaderNav
