import React, { FunctionComponent, useState } from 'react'

export interface LinkedinIconType {
  icon: 'LinkedinIcon'
  options?: LinkedinIconPropsType
}

interface LinkedinIconPropsType {
  className?: string
  color: LinkedinIconStateType
  hoverColor: LinkedinIconStateType
}

interface LinkedinIconStateType {
  colorPrimary: string
  colorSecondary: string
}

const LinkedinIcon: FunctionComponent<LinkedinIconPropsType> = (props) => {
  const defaultTheme = props.color
  const hoverTheme = props.hoverColor
  const [colorTheme, setColorTheme] = useState<LinkedinIconStateType>(
    defaultTheme
  )

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 30 30'
      width='100%'
      className={props.className?.toLowerCase()}
      onMouseEnter={() => setColorTheme(hoverTheme)}
      onMouseLeave={() => setColorTheme(defaultTheme)}
    >
      <circle fill={colorTheme.colorPrimary} cx='15' cy='15' r='15' />
      <path
        fill={colorTheme.colorSecondary}
        d='M24,16.21v6.18H20.38V16.62c0-1.45-.52-2.44-1.82-2.44a2,2,0,0,0-1.84,1.31,2.57,2.57,0,0,0-.12.88v6H13s.05-9.77,0-10.78H16.6v1.53l0,0h0v0a3.56,3.56,0,0,1,3.23-1.79c2.36,0,4.13,1.55,4.13,4.86ZM9.27,6.41a1.87,1.87,0,1,0,0,3.73h0a1.87,1.87,0,1,0,0-3.73Zm-1.82,16H11V11.61H7.45Z'
      />
    </svg>
  )
}

export default LinkedinIcon
