import React, { FunctionComponent, useState } from 'react'

export interface CheckIconType {
  icon: 'CheckIcon'
  options?: CheckIconPropsType
}

interface CheckIconPropsType {
  className?: string
  color: CheckIconStateType
  hoverColor: CheckIconStateType
}

interface CheckIconStateType {
  colorPrimary: string
  colorSecondary: string
}

const CheckIcon: FunctionComponent<CheckIconPropsType> = (props) => {
  const defaultTheme = props.color
  const hoverTheme = props.hoverColor
  const [colorTheme, setColorTheme] = useState<CheckIconStateType>(defaultTheme)

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
      width='100%'
      className={props.className?.toLowerCase()}
      onMouseEnter={() => setColorTheme(hoverTheme)}
      onMouseLeave={() => setColorTheme(defaultTheme)}
    >
      <g>
        <circle fill={colorTheme.colorPrimary} cx='10' cy='10' r='10' />
        <path
          fill={colorTheme.colorSecondary}
          d='M9,14a1,1,0,0,1-.71-.29l-3-3A1,1,0,0,1,6.71,9.29l2.22,2.23,4.3-5.16a1,1,0,0,1,1.54,1.28l-5,6a1,1,0,0,1-.72.36Z'
        />
      </g>
    </svg>
  )
}

export default CheckIcon
