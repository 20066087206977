import LogoRight from 'EasyRedmineGatsby/images/logo-ms.png'
import Logo from 'EasyRedmineGatsby/images/logo-redmine.svg'
import LogoLeft from 'EasyRedmineGatsby/images/logo-redmine-footer.svg'
import { Link } from 'gatsby'
import React from 'react'
import PuxSocials from 'Shared/components/PuxSocials/PuxSocials'
import { getHrefLang, getLocalizedUrl } from 'Shared/utils/localeURL'
import { t } from 'ttag'

import { useFooterData } from '../../queries/_use-footer-data'

interface ContentMenuItem {
  contentType: 'ContentMenuItem'
  displayText: string
  render: string
}
interface LinkMenuItem {
  contentType: 'LinkMenuItem'
  linkMenuItem: {
    name: string
    url: string
  }
}

type MenuItem = ContentMenuItem | LinkMenuItem
interface CategoryItem {
  categoryItemName: string
  menuItemsList: {
    menuItems: MenuItem[]
  }
}
interface FooterData {
  menuItems: CategoryItem[]
}

const rootClass = `footer`

const getLink = (item: MenuItem) => {
  switch (item.contentType) {
    case `ContentMenuItem`:
      const itemData = JSON.parse(item.render)
      return (
        <Link
          to={`${getLocalizedUrl(itemData.url)}`}
        >
          {item.displayText}
        </Link>
      )
    case `LinkMenuItem`:
      return <a href={item.linkMenuItem.url}>{item.linkMenuItem.name}</a>
  }
}

const Footer: React.FunctionComponent = () => {
  const footerData: FooterData = useFooterData()
  const year = `${new Date().getFullYear()}`

  return (
    <footer className={rootClass}>
      <div className='pux-container'>
        <div className={`${rootClass}-logo`}>
          <img src={Logo} alt={t`Redmine.Footer.Logo`} />
        </div>

        <div className={`${rootClass}-navigation`}>
          {footerData.menuItems.map((cat, catIdx) => (
            <div className={`${rootClass}-col`} key={catIdx}>
              <div className={`${rootClass}-col-content`}>
                <h4 className={`${rootClass}-col-title`}>
                  {cat.categoryItemName}
                </h4>
                <ul className={`${rootClass}-col-list`}>
                  {cat.menuItemsList?.menuItems &&
                    cat.menuItemsList.menuItems.length &&
                    cat.menuItemsList.menuItems.map((item, itemIdx) => (
                      <li key={itemIdx}>{getLink(item)}</li>
                    ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        <div className={`${rootClass}-partners`}>
          <div className={`${rootClass}-partners-left`}>
            <img
              src={LogoLeft}
              alt={t`Redmine.Footer.LogoLeft`}
              width={170}
              height={38}
            />
          </div>
          <div className={`${rootClass}-partners-middle`}>
            <p>{t`Redmine.Footer.Partners`}</p>
          </div>
          <div className={`${rootClass}-partners-right`}>
            <img
              src={LogoRight}
              alt={t`Redmine.Footer.LogoRight`}
              width={150}
              height={63}
            />
          </div>
        </div>

        <div className={`${rootClass}-foot`}>
          <div className={`${rootClass}-social`}>
            <p className={`${rootClass}-social-text`}>
              {t`Redmine.Footer.SocialMedia`}
            </p>
            <PuxSocials
              urlFacebook={t`Redmine.Social.Facebook`}
              urlTwitter={t`Redmine.Social.Twitter`}
              urlLinkedin={t`Redmine.Social.Linkedin`}
              urlYoutube={t`Redmine.Social.Youtube`}
            />
          </div>
          <div className={`${rootClass}-copyright`}>
            {t`Redmine.Footer.Copyright`.replace(`%currentYear%`, year)}
            <span className={`${rootClass}-sitemap`}>
              <Link to={`/sitemap.xml`}>{t`Redmine.Footer.SiteMap`}, XML</Link>
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
