import React, { FunctionComponent, useState } from 'react'

export interface TwitterIconType {
  icon: 'TwitterIcon'
  options?: TwitterIconPropsType
}

interface TwitterIconPropsType {
  className?: string
  color: TwitterIconStateType
  hoverColor: TwitterIconStateType
}

interface TwitterIconStateType {
  colorPrimary: string
  colorSecondary: string
}

const TwitterIcon: FunctionComponent<TwitterIconPropsType> = (props) => {
  const defaultTheme = props.color
  const hoverTheme = props.hoverColor
  const [colorTheme, setColorTheme] = useState<TwitterIconStateType>(
    defaultTheme
  )

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 30 30'
      width='100%'
      className={props.className?.toLowerCase()}
      onMouseEnter={() => setColorTheme(hoverTheme)}
      onMouseLeave={() => setColorTheme(defaultTheme)}
    >
      <circle fill={colorTheme.colorPrimary} cx='15' cy='15' r='15' />
      <path
        fill={colorTheme.colorSecondary}
        d='M24.19,9.58a6.83,6.83,0,0,1-2.06.56,3.58,3.58,0,0,0,1.58-2A7.33,7.33,0,0,1,21.43,9a3.59,3.59,0,0,0-6.11,3.27A10.18,10.18,0,0,1,7.93,8.55,3.58,3.58,0,0,0,9,13.34a3.61,3.61,0,0,1-1.63-.45v0a3.58,3.58,0,0,0,2.88,3.51,3.28,3.28,0,0,1-.94.13,3.74,3.74,0,0,1-.68-.06A3.6,3.6,0,0,0,12,19a7.2,7.2,0,0,1-4.45,1.53,8.11,8.11,0,0,1-.86-.05,10.2,10.2,0,0,0,15.7-8.59c0-.16,0-.31,0-.46a7.36,7.36,0,0,0,1.79-1.86Z'
      />
    </svg>
  )
}

export default TwitterIcon
