import React, { FunctionComponent, useState } from 'react'

export interface CloseIconType {
  icon: 'CloseIcon'
  options?: CloseIconPropsType
}

interface CloseIconPropsType {
  className?: string
  color: CloseIconStateType
  hoverColor: CloseIconStateType
}

interface CloseIconStateType {
  colorPrimary: string
}

const CloseIcon: FunctionComponent<CloseIconPropsType> = (props) => {
  const defaultTheme = props.color
  const hoverTheme = props.hoverColor
  const [colorTheme, setColorTheme] = useState<CloseIconStateType>(defaultTheme)

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 18 18'
      className={props.className?.toLowerCase()}
      onMouseEnter={() => setColorTheme(hoverTheme)}
      onMouseLeave={() => setColorTheme(defaultTheme)}
    >
      <g>
        <path
          fill={colorTheme.colorPrimary}
          d='M17.71,16.29a1,1,0,0,1,0,1.42,1,1,0,0,1-1.42,0L9,10.41l-7.29,7.3a1,1,0,0,1-1.42,0,1,1,0,0,1,0-1.42L7.59,9,.29,1.71A1,1,0,0,1,1.71.29L9,7.59,16.29.29a1,1,0,1,1,1.42,1.42L10.41,9Z'
        />
      </g>
    </svg>
  )
}

export default CloseIcon
