import './PuxSocials.scss'

import React, { FunctionComponent } from 'react'

import PuxIconBuilder from '../PuxIconBuilder/PuxIconBuilder'

interface PuxSocialsType {
  urlFacebook?: string
  urlTwitter?: string
  urlLinkedin?: string
  urlYoutube?: string
}

const rootClass = `pux-socials`

const PuxSocials: FunctionComponent<PuxSocialsType> = (props) => {
  return (
    <ul className={`${rootClass}`}>
      {props.urlFacebook && (
        <li>
          <a href={props.urlFacebook} target='_blank' rel='noopener'>
            <PuxIconBuilder icon='FacebookIcon' />
          </a>
        </li>
      )}
      {props.urlTwitter && (
        <li>
          <a href={props.urlTwitter} target='_blank' rel='noopener'>
            <PuxIconBuilder icon='TwitterIcon' />
          </a>
        </li>
      )}
      {props.urlLinkedin && (
        <li>
          <a href={props.urlLinkedin} target='_blank' rel='noopener'>
            <PuxIconBuilder icon='LinkedinIcon' />
          </a>
        </li>
      )}
      {props.urlYoutube && (
        <li>
          <a href={props.urlYoutube} target='_blank' rel='noopener'>
            <PuxIconBuilder icon='YoutubeIcon' />
          </a>
        </li>
      )}
    </ul>
  )
}

export default PuxSocials
