import React, { FunctionComponent, useState } from 'react'

export interface ArrowRightIconType {
  icon: 'ArrowRightIcon'
  options?: ArrowRightIconPropsType
}

interface ArrowRightIconPropsType {
  className?: string
  color: ArrowRightIconStateType
  hoverColor: ArrowRightIconStateType
}

interface ArrowRightIconStateType {
  colorPrimary: string
}

const ArrowRightIcon: FunctionComponent<ArrowRightIconPropsType> = (props) => {
  const defaultTheme = props.color
  const hoverTheme = props.hoverColor
  const [colorTheme, setColorTheme] = useState<ArrowRightIconStateType>(
    defaultTheme
  )

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 14'
      width='100%'
      className={props.className?.toLowerCase()}
      onMouseEnter={() => setColorTheme(hoverTheme)}
      onMouseLeave={() => setColorTheme(defaultTheme)}
    >
      <g transform='matrix(-1, 0, 0, 1, 19.997854, 0)'>
        <path
          fill={colorTheme.colorPrimary}
          d='M20,7a1,1,0,0,1-1,1H3.41l4.3,4.29a1,1,0,0,1,0,1.42A1,1,0,0,1,7,14a1,1,0,0,1-.71-.29l-6-6a1,1,0,0,1-.21-.33.92.92,0,0,1,0-.76,1,1,0,0,1,.21-.33l6-6A1,1,0,0,1,7.71,1.71L3.41,6H19A1,1,0,0,1,20,7Z'
        />
      </g>
    </svg>
  )
}

export default ArrowRightIcon
