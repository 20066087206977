import React, { FunctionComponent, useState } from 'react'

export interface StarIconType {
  icon: 'StarIcon' | 'StarActiveIcon'
  options?: StarIconPropsType
}

interface StarIconPropsType {
  className?: string
  color: StarIconStateType
  hoverColor: StarIconStateType
}

interface StarIconStateType {
  colorPrimary: string
}

const StarIcon: FunctionComponent<StarIconPropsType> = (props) => {
  const defaultTheme = props.color
  const hoverTheme = props.hoverColor
  const [colorTheme, setColorTheme] = useState<StarIconStateType>(defaultTheme)

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0.046 -0.054 20.947 20.032'
      width='100%'
      className={props.className?.toLowerCase()}
      onMouseEnter={() => setColorTheme(hoverTheme)}
      onMouseLeave={() => setColorTheme(defaultTheme)}
    >
      <g>
        <path
          fill={colorTheme.colorPrimary}
          d='M 20.14 6.84 L 14.1 6 L 11.4 0.51 C 11.064 -0.183 10.105 -0.253 9.673 0.384 C 9.645 0.424 9.621 0.466 9.6 0.51 L 6.9 6 L 0.9 6.88 C 0.522 6.934 0.208 7.197 0.09 7.56 C -0.018 7.914 0.078 8.299 0.34 8.56 L 4.71 12.8 L 3.71 18.8 C 3.637 19.183 3.793 19.573 4.11 19.8 C 4.405 20.004 4.786 20.035 5.11 19.88 L 10.5 17 L 15.91 19.83 C 16.051 19.906 16.21 19.944 16.37 19.94 C 16.582 19.941 16.788 19.874 16.96 19.75 C 17.277 19.523 17.433 19.133 17.36 18.75 L 16.36 12.75 L 20.7 8.55 C 20.962 8.289 21.058 7.904 20.95 7.55 C 20.841 7.176 20.525 6.899 20.14 6.84 Z'
        />
      </g>
    </svg>
  )
}

export default StarIcon
