import React, { FunctionComponent, useState } from 'react'

export interface PinterestIconType {
  icon: 'PinterestIcon'
  options?: PinterestIconPropsType
}

interface PinterestIconPropsType {
  className?: string
  color: PinterestIconStateType
  hoverColor: PinterestIconStateType
}

interface PinterestIconStateType {
  colorPrimary: string
  colorSecondary: string
}

const PinterestIcon: FunctionComponent<PinterestIconPropsType> = (props) => {
  const defaultTheme = props.color
  const hoverTheme = props.hoverColor
  const [colorTheme, setColorTheme] = useState<PinterestIconStateType>(
    defaultTheme
  )

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 30 30'
      width='100%'
      className={props.className?.toLowerCase()}
      onMouseEnter={() => setColorTheme(hoverTheme)}
      onMouseLeave={() => setColorTheme(defaultTheme)}
    >
      <circle fill={colorTheme.colorPrimary} cx='15' cy='15' r='15' />
      <path
        d='M16.21,20.09a5.11,5.11,0,0,1-2.5-1.19c-.49,2.56-1.09,5-2.85,6.3-.55-3.87.8-6.78,1.42-9.87-1.06-1.8.13-5.42,2.38-4.53,2.77,1.1-2.4,6.68,1.07,7.38,3.63.73,5.11-6.28,2.86-8.57-3.25-3.29-9.45-.07-8.69,4.64.19,1.16,1.38,1.51.48,3.1-2.08-.46-2.7-2.1-2.62-4.29a7,7,0,0,1,6.31-6.42c3.91-.44,7.58,1.43,8.09,5.12.57,4.15-1.77,8.65-5.95,8.33Z'
        fill={colorTheme.colorSecondary}
      />
    </svg>
  )
}

export default PinterestIcon
