import React, { FunctionComponent, useState } from 'react'

export interface SearchIconType {
  icon: 'SearchIcon'
  options?: SearchIconPropsType
}

interface SearchIconPropsType {
  className?: string
  color: SearchIconStateType
  hoverColor: SearchIconStateType
}

interface SearchIconStateType {
  colorPrimary: string
  colorSecondary: string
}

const SearchIcon: FunctionComponent<SearchIconPropsType> = (props) => {
  const defaultTheme = props.color
  const hoverTheme = props.hoverColor
  const [color, setColor] = useState<string>(props.color.colorPrimary)

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
      className={props.className?.toLowerCase()}
      onMouseEnter={() => setColor(props.color.colorSecondary)}
      onMouseLeave={() => setColor(props.color.colorPrimary)}
    >
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Vrstva_1' data-name='Vrstva 1'>
          <path
            fill={color}
            d='M19.71,18.29,16,14.61A9,9,0,1,0,14.61,16l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,19.71,18.29ZM9,16a7,7,0,1,1,7-7A7,7,0,0,1,9,16Z'
          />
          7
        </g>
      </g>
    </svg>
  )
}

export default SearchIcon
