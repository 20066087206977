import React, { FunctionComponent, useState } from 'react'

export interface ChevronIconType {
  icon: 'ChevronIcon'
  options?: ChevronIconPropsType
}

interface ChevronIconPropsType {
  className?: string
  color: ChevronIconStateType
  hoverColor: ChevronIconStateType
}

interface ChevronIconStateType {
  colorPrimary: string
}

const ChevronIcon: FunctionComponent<ChevronIconPropsType> = (props) => {
  const defaultTheme = props.color
  const hoverTheme = props.hoverColor
  const [colorTheme, setColorTheme] = useState<ChevronIconStateType>(defaultTheme)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 6 10"
      className={props.className?.toLowerCase()}
      onMouseEnter={() => setColorTheme(hoverTheme)}
      onMouseLeave={() => setColorTheme(defaultTheme)}
    >
      <path
        fill={colorTheme.colorPrimary}
        d="M5.71,5.71l-4,4a1,1,0,0,1-1.42,0,1,1,0,0,1,0-1.42L3.59,5,.29,1.71A1,1,0,0,1,1.71.29l4,4A1,1,0,0,1,5.71,5.71Z"
      />
    </svg>
  )
}

export default ChevronIcon
